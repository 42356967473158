import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { questions } from "../data/faq.questions";
import { LinkBehavior } from "../styles/theme";
import { useEffect, useRef } from "react";
import './faq.scss';

function FaqPage() {
  const mobileVideoRef = useRef();

  useEffect(() => {
    mobileVideoRef.muted = true;
    mobileVideoRef.current.play();
  }, [mobileVideoRef]);

  return (
    <>
      <div className='container-faq'>
        <video ref={mobileVideoRef} className="background-video" autoPlay muted loop playsInline poster="/images/posters/faq.png">
          <source src="/video/faq.mp4" type="video/mp4" />
        </video>
        <Typography
          variant="h4"
          color="primary.dark"
        >Q&A</Typography>
        <Typography
          variant="h6"
          color="primary.dark"
          sx={{ margin: 2, textAlign: 'center' }}
        >May we provide you with additional information?</Typography>
        <Box sx={{ mt: 2 }}>
          <Button href="/contact-us" component={LinkBehavior} variant="contained" color="primary" sx={{ mr: 2 }}>Request more information</Button>
        </Box>
      </div>
      <Box
        sx={{
          backgroundColor: "#f2f2f2",
          p: { xs: "0px", sm: "80px" },
          pt: { xs: "80px" },
          pb: { xs: "80px" },
        }}>
        {questions.map(({ question, answer }, index) => (

          <Accordion key={`question-${index}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id={`question-${index}`}
            >
              <Typography ><b>{question}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box >
    </>
  )
}

export default FaqPage;