import { Box, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { menuItems } from '../../data/menuItems';
import { UnisonLogo } from '../../icons/UnisonLogo';
import { SocialIcon } from 'react-social-icons'
import 'react-social-icons/linkedin'
import 'react-social-icons/instagram'
import 'react-social-icons/facebook'

const email = "unisoncontact@unison-health.com";
const phoneNumbers = ["+34 605 91 10 54", "+34 608 595 128"];
function Footer() {


  return (
    <Box
      component="footer"
      sx={{
        paddingY: "4rem",
        bgcolor: "primary.dark",
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        gap: '60px',
        alignItems: { xs: 'center', sm: 'normal' },
        flexDirection: { xs: 'column', sm: 'row' }
      }}>

      <Box
        component="section"
        width='250px'
        sx={{
          order: { xs: 0, sm: "unset" }
        }}
      >
        <Typography
          variant="h6"
          align='center'
          color="primary.contrastText"
          sx={{ mb: "1.5rem", }}
        >
          Contact us
        </Typography>
        <List>
          <ListItem
            disableGutters
            sx={{ mb: "1rem", height: '3rem' }}
          >
            <ListItemIcon sx={{ minWidth: '0px' }}>
              <MailOutlineIcon sx={{ color: "primary.contrastText" }} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ pl: "1rem", color: "primary.contrastText", textAlign: "left" }}
              primary={
                <Link href={`mailto:${email}`} underline="none" color="inherit" >{email}</Link>
              } />
          </ListItem>

          <ListItem
            disableGutters
          >
            <ListItemIcon sx={{ minWidth: '0px' }}>
              <PhoneIcon sx={{ color: "primary.contrastText" }} />
            </ListItemIcon>
            <Box>
              {phoneNumbers.map(phoneNumber => (
                <ListItemText
                  key={phoneNumber}
                  primaryTypographyProps={{ pl: "1rem", color: "primary.contrastText", textAlign: "left" }}
                  primary={
                    <Link sx={{ "&:hover": { cursor: "default" } }} href={`tel:${phoneNumber}`} underline="none" color="inherit" >{phoneNumber}</Link>
                  } />
              ))}
            </Box>
          </ListItem>
        </List>
      </Box>
      <Box
        component="section"
        width='250px'
        sx={{
          order: { xs: 2, sm: "unset" }
        }}

      >
        <Typography
          variant="h6"
          align='center'
          color="primary.contrastText"
          sx={{ mb: "1.5rem" }}
        >
          Menu
        </Typography>
        <List>
          {menuItems
            .map(({ id, link, text, icon: ItemIcon }) => (
              <ListItem
                key={id}
                disableGutters
                sx={{ mb: "1rem", height: '3rem' }}
              >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                  <ItemIcon sx={{ color: "primary.contrastText" }} />
                </ListItemIcon>
                <ListItemButton component={Link} isAnchorLink href={link} sx={{ color: "primary.contrastText", textDecoration: 'none' }}>
                  <Typography textAlign="left">{text}</Typography>
                </ListItemButton>
              </ListItem>)
            )
          }
        </List>

      </Box>
      <Box
        component="section"
        sx={{
          order: { xs: 1, sm: "unset" },
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignContent: 'flex-start',
          justifyContent: 'center'
        }}>
        <Typography
          variant="h6"
          align='center'
          color="primary.contrastText"
          sx={{ width: "100%", mb: "1.5rem" }}>
          Follow us
        </Typography>
        <SocialIcon url="https://www.linkedin.com/company/unison-health1/" target="_blank" />
        <SocialIcon url="https://www.facebook.com/people/Unison-Health/61555975865386/" target="_blank" />
        <SocialIcon url="https://www.instagram.com/unison_health" target="_blank" />
      </Box>
      <Box
        component="section"
        width="100%"
        sx={{
          textAlign: 'center',
          order: { xs: 3, sm: "unset" },
        }}>
        <UnisonLogo sx={{ fontSize: 150 }} />
      </Box>

    </Box>
  )
}

export default Footer;