export const questions = [
  {
    question: "How is the program clinically managed? What to Expect? ",
    answer: "Our program provides support for children and adolescents who require more intensive  treatment due to level of mental health or substance use challenges.  Patients participate in programs Monday-Friday in our morning or afternoon sessions. Each session is 3 hours in length and includes individual, family and group therapies. In addition, each patient will be supported by our team of  psychiatrist, psychiatric nurse, psychologist, occupational therapist and/or substance use specialized counselor. The Unison Care team determines with the patient's primary doctor and psychiatrist the level of frequency needed to support clinical changes.\n\nThe goal is to support the patient to increase involvement in age-appropriate activities, including educational, social, family and extracurricular activities that promote mental health and stability.\n\nIntensive Family involvement is expected as well as regular drug screenings if enrolled in the Substance Use disorder program.  "
  },
  {
    question: "How is the day organized into different treatment sessions?",
    answer: "Depending on the patient’s treatment plan the day is organized into 3 clinical settings.\nEach day begins with a 1: 1 or family check in depending on the clinical situation.This is followed by group sessions with peers to facilitate skills related to self- regulation, mindfulness and insight oriented processes.During the  week the patient will meet with their psychiatrist / nurse practitioner as well as Family therapist. "
  },
  {
    question: "What is the typical length of treatment in the IOP program? ",
    answer: "The average length of treatment is 4-8 weeks. Following the program completion it is often recommended to transition to an outpatient clinical setting that is able to follow the family and patient.  We begin discharge planning at the start of the IOP, not at the end, as our goal is to support patients to reintegrate into their life activities and relations. "
  },
  {
    question: "How does the Unison Health care team decide which program is right for my child/adolescent?",
    answer: "The process of care begins at the intake, to learn if an individual is a good fit for our Program.\nIn addition we are able to understand following this first intake phase the level of care needed.\nIf the care may not be provided at our center a referral will be made to other providers or programs based on need."
  },
  {
    question: "Will insurance cover the Unison Health care programs? ",
    answer: "Unison is in-network with most American and International/Global Insurance companies. We do require an authorization/doctor’s order  for our IOP program. If Unison is out of network with your insurance company, your claim may be submitted to your insurance to know your out of pocket coverage. We know this can be a complex situation to manage, especially during a crisis, our Claims processing Team is available to assist as needed.",
  },
]