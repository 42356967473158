import { Box, Container, Typography } from "@mui/material";

const iframeHeight = 1000;

const paddingTop = 80;
const paddingBottom = 80;

function ContactUs() {
  return (
    <>
      <Container
        maxWidth='100%'
        sx={
          {
            top: "0",
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            backgroundImage: 'url("/images/contact-us.jpeg")',
            backgroundBlendMode: 'overlay',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: { xs: '350px', sm: '560px', xl: '600px' },
            zIndex: '0',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: '-1',
              background: 'linear-gradient(135deg, #475f72b5 0%, rgba(255, 255, 255, 0) 100%)'
            }
          }
        }>
        <Typography
          variant="h4"
          color="primary.contrastText"
        >Contact us</Typography>

      </Container>
      <Box
        sx={{
          position: 'relative',
          height: `${iframeHeight + paddingTop + paddingBottom}px`,
          paddingTop: '80px',
          paddingBottom: '80px',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <iframe
          title="contact-us-unison-health"
          aria-label='Contact Us'
          scrolling="no"
          style={{
            position: 'absolute',
            top: `${paddingTop}px`,
            height: `${iframeHeight}px`,
            width: "100%",
            maxWidth: "1000px",
            border: "none",
            overflow: "hidden",

          }}
          src='https://forms.zohopublic.eu/unisonhealth/form/ContactUs/formperma/pUC4qugXWCNl_rg6Z_lA5JuScos--HhgDBhpXvRjgAk' ></iframe>
      </Box >
    </>
  )
}

export default ContactUs;