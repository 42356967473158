export const programs = [
  {
    title: "On Site",
    imgSrc: "images/pexels/children.jpg",
    groups: [
      {
        title: "Children (Ages 8-12)",
        groupPrograms: [
          {
            title: "Outpatient and Intensive Outpatient Programs",
            description: "Our programs provide outpatient care or Intensive Outpatient care, depending on medical need. Children who require more intensive treatment due to the inability to manage daily life, engage in daily routines and are unable to attend school may be eligible for our IOP treatment.  Enrollment in our IOP program includes  3 sessions, each lasting 3 hours (9 hours total per week)."
          },
          {
            title: "Mood and Anxiety Programs",
            description: "Provides short-term intensive treatment for children whose behavioral health symptoms significantly impact daily life functioning. Group, family and individual therapy work together to stabilize symptoms, promote coping skills, insight while improving meaningful connection with peers and family."
          },
          {
            title: "After school Programs",
            description: "Our after school program is for children who are able to attend school but still require intensity to manage emotional distress while developing new coping skills that can be generalized to the home and school environments."
          },
        ]
      },
      {
        title: "Adolescent (Ages 13-18)",
        groupPrograms: [
          {
            title: "Outpatient and Intensive Outpatient Programs",
            description: "Our programs provide outpatient care or Intensive Outpatient care depending on medical need. Adolescents who require more intensive treatment due to the inability to manage daily life, engage in daily routines and are unable to attend school may be eligible for our IOP treatment.  Enrollment in our IOP program includes  3 sessions, each lasting 3 hours (9 hours total per week)."
          },
          {
            title: "Mood and Anxiety Programs",
            description: "Provides short-term intensive treatment for adolescents whose behavioral health symptoms significantly impact daily life functioning. Group, family and individual therapy work together to stabilize symptoms, promote coping skills, insight while improving meaningful connection with peers and family."
          },
          {
            title: "After school Programs",
            description: "Our after school program is for Adolescents  who are able to attend school but still require intensity to manage emotional distress while developing new coping skills that can be generalized to the home and school environments."
          }
        ]
      },
      {
        title: "Adolescent Substance Use (Ages 13-18)",
        groupPrograms: [
          {
            title: "Outpatient and Intensive Outpatient Programs",
            description: "Our programs provide outpatient care or Intensive Outpatient care depending on medical need. Adolescents who require more intensive treatment due to the inability to manage daily life, engage in daily routines and are unable to attend school may be eligible for our IOP treatment.  Enrollment in our IOP program includes   3 sessions, each lasting 3 hours (9 hours total per week)."
          },
          {
            title: "Mental Health and Substance Use Programs",
            description: "Provides short-term intensive treatment for adolescents whose behavioral health symptoms and substance use behaviors significantly impact daily life functioning. Group, family and individual therapy work together to stabilize symptoms, promote coping skills, insight while improving meaningfull connection with peers and family. Adolescents in this program will be followed by our clinicians specializing in Substance use. Urine drug screening is required based on treatment team recommendations."
          },
          {
            title: "After school Programs",
            description: "Our after school program is for Adolescents  who are able to attend school but still require intensity to manage emotional distress while developing new coping skills that can be generalized to the home and school environments."
          }
        ]
      },
      {
        title: "Adults",
        groupPrograms: [
          {
            title: "",
            description: "Unison Health offers psychological services for Adults who have challenges related to depression, anxiety, trauma, grief/loss, and/or addiction.When it becomes too difficult to participate in daily life routines, work, and/or social and family relationships are constrained. Talking to a psychologist can be helpful, at Unison we provide services for a range of difficulties impacting your life."
          }
        ]
      },
      {
        title: "Psychiatry",
        groupPrograms: [
          {
            title: "",
            description: "Unison Health offers psychiatry services for patients who are having significant difficulty with sleep, mood, participation in daily routines and activities. Psychiatrists are medical doctors who are able to prescribe medication when symptoms are too destabilizing and/or psychotherapy is not enough. Often a combination of psychiatric and psychological treatment is most effective for change."
          }
        ]
      }
    ]
  },
  {
    title: "Virtual",
    imgSrc: "images/pexels/teenagers.jpg",
    groups: [
      {
        title: "Adolescent (Ages 13-18)",
        groupPrograms: [
          {
            title: "Outpatient and Intensive Outpatient Programs",
            description: "Our programs provide outpatient care or Intensive Outpatient care depending on medical need. Adolescents who require more intensive treatment due to the inability to manage daily life, engage in daily routines and are unable to attend school may be eligible for our IOP treatment.  Enrollment in our IOP program includes  3 sessions, each lasting 3 hours (9 hours total per week)."
          },
          {
            title: "Mood and Anxiety Programs",
            description: "Provides short-term intensive treatment for adolescents whose behavioral health symptoms significantly impact daily life functioning. Group, family and individual therapy work together to stabilize symptoms, promote coping skills, insight while improving meaningful connection with peers and family."
          }
        ]
      },
      {
        title: "Adults",
        groupPrograms: [
          {
            title: "",
            description: "Unison Health offers psychological services for Adults who have challenges related to depression, anxiety, trauma, grief/loss, and/or addiction.When it becomes too difficult to participate in daily life routines, work, and/or social and family relationships are constrained. Talking to a psychologist can be helpful, at Unison we provide services for a range of difficulties impacting your life."
          }
        ]
      },
      {
        title: "Psychiatry",
        groupPrograms: [
          {
            title: "",
            description: "Unison Health offers psychiatry services for patients who are having significant difficulty with sleep, mood, participation in daily routines and activities. Psychiatrists are medical doctors who are able to prescribe medication when symptoms are too destabilizing and/or psychotherapy is not enough. Often a combination of psychiatric and psychological treatment is most effective for change."
          }
        ]
      }
    ]
  },
]