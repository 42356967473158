import React from "react";
import HeaderFooterLayout from "./components/HeaderFooterLayout/HeaderFooterLayout";
import Home from "./pages/Home.page";
import FaqPage from "./pages/Faq.page";
import ContactUs from "./pages/ContactUs.page";

const routes = [
  {
    path: "/",
    element: <HeaderFooterLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/faq",
        element: <FaqPage />
      },
      {
        path: "/contact-us",
        element: <ContactUs />
      },
    ]
  }
]

export default routes;