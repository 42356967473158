import { useEffect, useRef } from 'react';
import Programs from "./OnSitePrograms.page";
import './home.scss';
import { Box, Button, Typography } from '@mui/material';
import { presentationText } from '../data/presentationtext';
import LocationPage from './Location.page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { LinkBehavior } from '../styles/theme';

function Home() {
  const desktopVideoRef = useRef();
  const mobileVideoRef = useRef();

  useEffect(() => {
    desktopVideoRef.muted = true;
    desktopVideoRef.current.play();
  }, [desktopVideoRef]);

  useEffect(() => {
    mobileVideoRef.muted = true;
    mobileVideoRef.current.play();
  }, [mobileVideoRef]);

  return (<>
    <div className='container'>
      <video ref={mobileVideoRef} id="background-video" className="-mobile" autoPlay muted loop playsInline poster="/images/posters/teenagers-2.png">
        <source src="/video/teenagers-2.mp4" type="video/mp4" />
      </video>
      <video ref={desktopVideoRef} id="background-video" className="-desktop" autoPlay muted loop playsInline poster="/images/posters/teenagers-1.png">
        <source src="/video/teenagers-1.mp4" type="video/mp4" />
      </video>

      <div className='presentation'>
        <h3 className='up-fade-in-2'>Growing Together</h3>
        <h6 className='up-fade-in-4'>{presentationText.title}</h6>
        <Box className='up-fade-in-5' sx={{ mt: { xs: 0, sm: 2 } }} >
          <Button href="/contact-us" component={LinkBehavior} variant="contained" color="primary" sx={{ mr: 2 }}>Contact us</Button>
        </Box>
      </div>
      <Box
        sx={{
          position: "relative",
          margin: 3
        }}>
        <Box sx={{
          position: 'absolute',
          width: '2rem',
          height: '2rem',
          borderTop: "solid 2px grey",
          borderLeft: "solid 2px grey",
          top: 0,
          left: 0
        }}>
        </Box>
        <Typography
          variant='h5'
          sx={{
            m: 3,
          }}>
          {presentationText.subtitle}
        </Typography>
        <Box sx={{
          position: 'absolute',
          width: '2rem',
          height: '2rem',
          borderBottom: "solid 2px grey",
          borderRight: "solid 2px grey",
          bottom: 0,
          right: 0
        }}>
        </Box>
      </Box>
      <Programs />
      <FontAwesomeIcon icon={faLeaf} style={{ color: "#44554d", }} />
      <LocationPage />
    </div >
  </>)
}

export default Home;