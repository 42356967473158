import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment, useState } from "react";
import { LinkBehavior } from "../styles/theme";
import { useInView } from "react-intersection-observer";
import { programs } from '../data/programs'


function OnSiteProgramsPage() {
  const [selectedProgram, setSelectedProgram] = useState("On Site");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [titleRef, isTitleInView] = useInView({
    triggerOnce: true,
    rootMargin: '10px',
  })
  const [programsRef, areProgramsInView] = useInView({
    triggerOnce: true,
    rootMargin: '10px',
  })
  const [programsDetailsRef, areProgramsDetailsInView] = useInView({
    triggerOnce: true,
    rootMargin: '10px',
  })

  const getFullProgram = (programId) => {
    return programs.find(program => program.title === programId);
  }
  return (
    <>
      <Box className={`up-fade-in-2 ${isTitleInView ? '' : 'paused'}`} ref={titleRef}>

        <Typography
          variant="h4"
          color="primary.dark"
          sx={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}
        >On site and virtual programs available</Typography>
        <Typography
          variant="h6"
          color="primary.dark"
          sx={{ width: '100%', textAlign: 'center' }}
        >Choose your program</Typography>
      </Box >
      <Container
        ref={programsRef}
        className={`up-fade-in-2 ${areProgramsInView ? '' : 'paused'}`}
        id="programs"
        maxWidth='100%'
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          display: "flex",
          p: { xs: "0px", sm: "80px" },
          pb: { xs: "80px" },
          gap: "60px",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-around',

        }}>


        {programs.map(({ title, imgSrc }) => (
          <Button
            className=""
            key={title}
            component={LinkBehavior}
            onClick={() => setSelectedProgram(title)}
            href="#program-details"
            sx={{
              p: 0,
              textAlign: 'center',
              borderRadius: "50%",
              width: "300px",
              height: "300px",
              overflow: "hidden",
              position: "relative",
              "&:hover h6": {
                height: "100%",
                fontSize: "2rem",
                paddingTop: "40%"
              },
              "&:before": {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                right: "0",
                bottom: "0",
                width: '100%',
                height: '100%',
                zIndex: '1',
                background: 'linear-gradient(135deg, #475f72b5 0%, rgba(255, 255, 255, 0) 100%)'
              }
            }}>
            <img src={imgSrc} alt={title} width="100%" height="100%" loading="lazy" />
            <Typography
              color="primary.contrastText"
              variant="h6"
              sx={{
                transition: "height 0.5s ease-in-out, font-size 0.5s ease-in-out, padding-top 0.5s ease-in-out",
                position: "absolute",
                bottom: "0",
                left: "0",
                paddingTop: "20px",
                width: "100%",
                height: "30%",
                backgroundColor: "primary.dark",
                zIndex: "1",
              }}>{title}</Typography>
          </Button>
        ))}

      </Container >
      <Paper
        className={`up-fade-in-2 ${areProgramsDetailsInView ? '' : 'paused'}`}
        ref={programsDetailsRef}
        id="program-details"
        elevation={isMobile ? 0 : 3}
        sx={{
          padding: { xs: '10px', md: '30px' },
          margin: { xs: '0px', md: '30px' },
          maxWidth: '1100px',
        }}>
        {getFullProgram(selectedProgram).groups.map(({ title, groupPrograms }, index) =>
          <Accordion id={title.replace(" ", "-")} key={`question-${index}`} square sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id={`question-${index}`}
            >
              <Typography className="right-fade-in-2" variant="h5" sx={{ mb: "10px" }}>{`${selectedProgram} ${title}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                groupPrograms.map(({ title: groupProgramTitle, description }, index) => (
                  <Fragment key={`group-${index}`}>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>{groupProgramTitle}</Typography>
                    <Typography variant="subtitle1" sx={{ marginLeft: "20px", marginBottom: "20px" }}>{description}</Typography>
                  </Fragment>

                ))
              }

            </AccordionDetails>
          </Accordion>
        )}

      </Paper >
      <Box sx={{ mt: 2, mb: 5 }}>
        <Button
          href="/contact-us" component={LinkBehavior}
          variant="contained" color="primary" >Contact us</Button>
      </Box>

    </>
  )
}

export default OnSiteProgramsPage;