import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';

const locations = [
  {
    name: "Rota",
    imgSrc: "/images/locations/rota.jpg",
    address1: "C. Fuengirola, 22",
    address2: "11520 Rota, Cádiz",
    googleLocation: "",
  },
  {
    name: "Vithas Sevilla",
    imgSrc: "/images/locations/Vithas_encaramilla.jpeg",
    address1: "Calle Enramadilla, 8",
    address2: "41018 Sevilla",
    googleLocation: ""
  },
  {
    name: "Vithas Castilleja",
    imgSrc: "/images/locations/Vithas_castilleja.jpeg",
    address1: "Av. Plácido Fernández Viagas, s/n",
    address2: "41950 Castilleja de la Cuesta, Sevilla",
    googleLocation: ""
  },
]

function LocationPage() {

  return (
    <>
      <Typography
        id="location"
        variant="h4"
        color="primary.dark"
      >Locations</Typography>
      <Carousel
        sx={{ width: '100%' }}
        autoPlay
        interval={3000}
        cycleNavigation
        animation="slide"
        indicators
        duration={600}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={false}
        fullHeightHover
        swipe>
        {
          locations.map((location) => (
            <Card key={location.name}>
              <CardContent sx={{
                position: 'absolute',
                bottom: 0,
                backgroundColor: "#ffffffc9",
                width: { xs: "100%", md: "50%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                clipPath: { xs: "ellipse(659px 298px at -30% 110%)", md: "ellipse(450px 600px at 0% 100%)" },
              }}>
                <Typography

                  gutterBottom
                  variant="h4" >
                  {location.name}
                </Typography>
                <Typography
                  gutterBottom variant="h6" >
                  {location.address1}
                </Typography>
                <Typography
                  gutterBottom variant="h6" >
                  {location.address2}
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="500px"
                image={location.imgSrc}
                alt={location.name}
              />
            </Card>
          ))
        }
      </Carousel >
    </ >
  )
}

export default LocationPage;