import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box, Collapse, Divider, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Fragment } from "react";
import { menuItems } from "../../data/menuItems";

const drawerWidth = 240;

function MobileMenu(props) {
  const { isOpen, handleDrawerToggle, openSection, onClickToggleSection } = props;
  const container = window.document.body;
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <nav>
      <SwipeableDrawer
        anchor="right"
        container={container}
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        onOpen={() => { }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'flex', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            Unison Health
          </Typography>
          <Divider />
          <List>
            {menuItems.map(({ id, link, text, subSections }) => (
              <Fragment key={`drawer-${text}`} >
                <ListItem>
                  <ListItemButton component={Link} isAnchorLink href={link} sx={{ color: 'inherit', textDecoration: 'none' }}>
                    <Typography textAlign="left">{text}</Typography>
                  </ListItemButton>
                  {subSections &&
                    <IconButton aria-label="toggle-submenu" onClick={(e) => { onClickToggleSection(e, id) }}>
                      {openSection.startsWith(id) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>}
                </ListItem>
                {subSections && (
                  <Collapse key={`drawer-collapse-${text}`} in={openSection.startsWith(id)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {subSections.map(({ id: subId, link, text, extraListInfo }) => (
                        <Fragment key={text} >
                          <ListItem>
                            {link
                              ? (
                                <ListItemButton component={Link} isAnchorLink href={link} sx={{ pl: 4, color: 'inherit', textDecoration: 'none' }}>
                                  <Typography textAlign="left" sx={{ fontSize: '12px' }} >{text}</Typography>
                                </ListItemButton>
                              )
                              : (
                                <ListItemText sx={{ pl: 4, color: 'inherit' }}>
                                  <Typography textAlign="left" sx={{ fontSize: '12px' }} >{text}</Typography>
                                </ListItemText>
                              )
                            }
                            {extraListInfo &&
                              <IconButton aria-label="toggle-extraInfo" onClick={(e) => { onClickToggleSection(e, `${id}${subId}`) }}>
                                {openSection === `${id}${subId}` ? <ExpandLess /> : <ExpandMore />}
                              </IconButton>}
                          </ListItem>
                          <Collapse key={`drawer-collapse-${text}`} in={openSection === `${id}${subId}`} timeout="auto" unmountOnExit>
                            {extraListInfo.map(textInfo => (
                              <ListItemText key={textInfo} sx={{ pl: 6 }} disableTypography primary={<Typography textAlign="left" sx={{ fontSize: '10px' }}>{textInfo}</Typography>} />
                            ))}
                          </Collapse>

                        </Fragment>
                      ))
                      }
                    </List>
                  </Collapse>
                )}

              </Fragment>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </nav>
  )
}

export default MobileMenu;